<template>
  <div class="app-container" id="dmm">
    <div ref="headerQuery">
      <el-row class="headerClass">
        <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">药品元数据管理</el-col>
        <el-col :span="20">
          <el-form :model="queryParams" ref="queryForm" :inline="true" style="float: right">
            <el-form-item label="机构名称:" prop="orgName">
              <el-autocomplete
                v-model="queryParams.orgName"
                :fetch-suggestions="querySearchAsync"
                placeholder="机构名称"
                @select="handleQuery"
                clearable
                size="small"
                value-key="name"
                style="width: 200px;height: 36px!important;line-height: 36px;"
                @keydown.enter.native="handleQuery"
                @clear="setBlur()"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item label="上传时间:">
              <el-date-picker
                v-model="setDateRange"
                type="daterange"
                value-format="yyyy-MM-dd"
                style="width:250px"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="timeChange">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="状态:" prop="status">
              <el-select
                v-model="queryParams.status"
                placeholder="状态"
                clearable
                size="small"
                class="statusClass"
                style="width: 100px"
              >
                <el-option label="全部" value=""/>
                <el-option
                  v-for="dict in statusOptions"
                  :key="'statusOptions'+dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="上传方式:" prop="uploadType">
              <el-select
                v-model="queryParams.uploadType"
                placeholder="上传方式"
                clearable
                size="small"
                class="statusClass"
                style="width: 100px"
              >
                <el-option label="全部" value=""/>
                <el-option
                  v-for="dict in uploadMtthodOptions"
                  :key="'uploadMtthodOptions'+dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery()">查询</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <el-table
      ref="oraTableData"
      v-loading="loading"
      border
      stripe
      :height="tableHeight"
      :header-cell-style="{'text-align':'center'}"
      :cell-style="{'text-align':'center'}"
      :data="applyList">
      <el-table-column  label="记录ID" prop="recordId" width="120"/>
      <el-table-column  label="机构名称" prop="orgName" :show-overflow-tooltip="true"/>
      <el-table-column label="上传时间" align="center" prop="uploadTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.uploadTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="最近操作时间" align="center" prop="latestOperTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.latestOperTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="上传记录数" prop="recordCount" :show-overflow-tooltip="true"/>
      <el-table-column label="匹配度" prop="matchRatio" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span v-if="scope.row.matchRatio !== null && scope.row.matchRatio !== undefined && scope.row.matchRatio !== ''">{{ scope.row.matchRatio }}%</span>
        </template>
      </el-table-column>
      <el-table-column label="上传方式" align="center" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.uploadMethodStr === '接口上传'" class="jksc">{{ scope.row.uploadMethodStr }}</span>
          <span v-else-if="scope.row.uploadMethodStr === '文件上传'" class="wjsc">{{ scope.row.uploadMethodStr }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.statusStr === '待匹配'" class="dpp">{{ scope.row.statusStr }}</span>
          <span v-else-if="scope.row.statusStr === '已上传'" class="ysc">{{ scope.row.statusStr }}</span>
          <span v-else-if="scope.row.statusStr === '待处理'" class="dcl">{{ scope.row.statusStr }}</span>
          <span v-else-if="scope.row.statusStr === '匹配完成'" class="ppwc">{{ scope.row.statusStr }}</span>
          <span v-else-if="scope.row.statusStr === '已驳回'" class="ybh">{{ scope.row.statusStr }}</span>
          <span v-else-if="scope.row.statusStr === '已撤回'" class="ybh">{{ scope.row.statusStr }}</span>
          <span v-else-if="scope.row.statusStr === '已终止'" class="ybh">{{ scope.row.statusStr }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="180">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.statusStr === '待匹配'"
            size="mini"
            type="text"
            @click="codeMatchingClick(scope.row)"
          >代码匹配</el-button>
          <el-button
            v-if="scope.row.statusStr === '待匹配'"
            size="mini"
            type="text"
            @click="matchingClick(scope.row)"
          >药品匹配</el-button>
          <el-button
            v-if="scope.row.statusStr === '待处理'"
            size="mini"
            type="text"
            @click="handleClick(scope.row)"
          >处理</el-button>
          <el-button
            v-if="scope.row.statusStr === '匹配完成'"
            size="mini"
            type="text"
            @click="codeMatchingLookClick(scope.row)"
          >代码查看</el-button>
          <el-button
            v-if="scope.row.statusStr === '匹配完成'"
            size="mini"
            type="text"
            @click="lookClick(scope.row)"
          >药品查看</el-button>
          <el-button
            v-if="scope.row.statusStr === '已驳回'|| scope.row.statusStr === '已撤回' || scope.row.statusStr === '已终止'"
            size="mini"
            type="text"
            @click="handleInvalidClick(scope.row)"
          >查看</el-button>
        </template>
      </el-table-column>
      <template slot="empty" v-if="isResult === false">
        <img src="@/assets/images/no-booking.svg" alt="">
        <p>暂无数据</p>
      </template>
      <template slot="empty" v-else>
        <img src="@/assets/images/no-left-data.svg" alt="">
        <p>未查询到结果，请修改查询条件重试！</p>
      </template>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import {
  list,
  departmentList
} from "@/api/dm/drugMetadataMatching";
import { DEEP_CLONE } from '@/utils/constants'

export default {
  name: "drugMetatataMatching",
  data() {
    return {
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      setDateRange: [], // 创建时间范围
      serviceList: [],
      // 部门list
      departmentOptions: [

      ],
      // 状态list
      statusOptions: [
        // {
        //   value: "1",
        //   label: "已上传"
        // },
        {
          value: "3",
          label: "待匹配"
        },
        {
          value: "2",
          label: "待处理"
        },
        {
          value: "4",
          label: "匹配完成"
        },
        {
          value: "5",
          label: "已撤回"
        },
        {
          value: "6",
          label: "已驳回"
        },
        {
          value: "7",
          label: "已终止"
        }
      ],
      // 上传方式list
      uploadMtthodOptions: [
        {
          value: "1",
          label: "文件上传"
        },
        {
          value: "2",
          label: "接口上传"
        }
      ],
      // 查询参数
      queryParams: {
        startUploadTime: '',
        endUploadTime: '',
        orgName: '',
        // packId: '',
        pageNum: 1,
        pageSize: 10,
        status: '',
        uploadType: ''
      },
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      // 角色表格数据
      applyList: [],
      // // 弹出层标题
      // title: "",
      isResult: false
    };
  },
  created() {
    this.getList() // list
    // this.getServiceList() // 服务包list
  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    // 获取高度值 （内容高+padding+边框）
    var _this=this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      _this.setTableHeight()
    }

  },
  // mounted() {
  //   // 获取高度值 （内容高+padding+边框）
  //   let height = this.$refs.oraTableData.offsetHeight
  //   const that = this
  //   window.onresize = () => {
  //     return (() => {
  //       that.tableHeight = window.innerHeight - 100 - 100
  //     })()
  //   }
  //
  // },
  methods: {
    /*设置表格的高度*/
    setTableHeight(){
      // 获取高度值 （内容高+padding+边框）
      let formHeight = this.$refs.headerQuery.offsetHeight
      console.log(formHeight)
      this.tableHeight = window.innerHeight - formHeight-150
    },
    /* 部门名称检索*/
    querySearchAsync(queryString, cb) {
      if (queryString && queryString.trim() !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          let obj = {}
          obj.searchValue = queryString
          departmentList(obj).then(response => {
            console.log(response)
            let departNameList  = []
            response.data.forEach((item, index) => {
              let departObj = {}
              departObj.name = item
              departObj.val = index
              departNameList.push(departObj)
            })
            console.log(departNameList)
            cb(departNameList)
          })
        }, 200)
      } else {
        cb([])
      }
    },
    // 处理
    handleClick(row) {
      const recordId = row.recordId
      this.$router.push('/drugMetadataMatching/pretreatment/' + recordId)
      // this.$router.push({
      //   path: '/dm/uploadDocumentPretreatment',
      //   query: {
      //     recordId: recordId
      //   }
      // })
    },
    // scope.row.statusStr === '已驳回'|| scope.row.statusStr === '已撤回' || scope.row.statusStr === '已终止'"
    handleInvalidClick(row) {
      const recordId = row.recordId
      this.$router.push({
        path: '/drugMetadataMatching/pretreatment/' + recordId,
        query: {
          isInvalid: false // 按钮隐藏
        }
      })
    },
    // 代码匹配查看
    codeMatchingLookClick(row) {
      const orgId = row.orgId
      const recordId = row.recordId
      this.$router.push({
        path: '/drugMetadataMatching/codeMappingLook/' + orgId,
        query: {
          recordId: recordId,
          isLookShow: true
        }
      })
    },
    // 药品匹配查看
    lookClick(row) {
      const orgId = row.orgId
      const recordId = row.recordId
      this.$router.push({
        path: '/drugMetadataMatching/mappingLook/' + orgId,
        query: {
          recordId: recordId,
          isLookShow: true
        }
      })
    },
    codeMatchingClick(row) {
      const orgId = row.orgId
      const recordId = row.recordId
      console.log(this.$router)
      this.$router.push({
        path: '/drugMetadataMatching/codeMapping/' + orgId,
        query: {
          recordId: recordId
        }
      })
    },
    // 匹配
    matchingClick(row) {
      const orgId = row.orgId
      const recordId = row.recordId
      this.$router.push({
        path: '/drugMetadataMatching/mapping/' + orgId,
        query: {
          recordId: recordId
        }
      })
    },
    timeChange(val) {
      this.setDateRange = val
    },
    /** 查询药品元数据匹配列表 */
    getList() {
      this.loading = true;
      if (this.setDateRange) {
        this.queryParams.startUploadTime = this.setDateRange[0]
        this.queryParams.endUploadTime = this.setDateRange[1]
      } else {
        this.queryParams.startUploadTime = ''
        this.queryParams.endUploadTime = ''
      }
      list(this.queryParams).then(
        response => {
          // console.log(response)
          // console.log(response.data.rows)
          this.applyList = response.data.rows
          this.total = response.data.total
          this.loading = false
        }
      );
    },
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1
      this.getList();
    }
  }
};
</script>

<style lang="scss" scoped>
.dpp{
  display: inline-block;
  background: #FFEBD6;
  border-radius: 3px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #E4860A;
  width: 58px;
  height: 26px;
  line-height: 26px;
}
.ysc{
  display: inline-block;
  background: #D8E2FF;
  border-radius: 3px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #2B60F8;
  width: 58px;
  height: 26px;
  line-height: 26px;
}
.dcl{
  display: inline-block;
  background: #D8E2FF;
  border-radius: 3px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #2B60F8;
  width: 58px;
  height: 26px;
  line-height: 26px;
}
.ybh{
  display: inline-block;
  background: #FFE0E5;
  border-radius: 3px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FF3333;
  width: 58px;
  height: 26px;
  line-height: 26px;
}
.ppwc{
  display: inline-block;
  background: #D4FFCC;
  border-radius: 3px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #49B812;
  width: 70px;
  height: 26px;
  line-height: 26px;
}
.jksc{
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #2B60F8;
}
.wjsc{
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #49B812;
}
</style>
