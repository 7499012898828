import request from '@/utils/request'
// import { praseStrEmpty } from '@/utils/common'

// 药品元数据匹配列表查询
export function list(data) {
  return request({
    url: '/dm/drug/pre/upload/list',
    method: 'get',
    params: data
  })
}
// 根据查询条件查询部门id集合
export function departmentList(data) {
  return request({
    url: '/dm/drug/pre/select/name/list',
    method: 'get',
    params: data
  })
}
